<template>
  <div>
    <v-dialog v-model="isPreviewing" fullscreen
      hide-overlay
      transition="dialog-bottom-transition">
      <v-card class="d-flex flex-column">

        <v-card-title>
          <span>Previewing As {{ previewState.previewTarget.first_name }}</span>
          <v-btn @click="closePreview()" class="ml-auto">Close</v-btn>
        </v-card-title>

        <v-card-text class="flex-grow-1">
          <iframe id="previewWindow" ref="iframeWindow" :src="iframeUrl" frameborder="0" @load="handleIframeLoaded"></iframe>
        </v-card-text>
        
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "@axios";
import { ref } from "@vue/composition-api";
import { useViewAs } from "@/composables/accounts/view-as";

export default {
  name: "ViewAs",
  setup() {
    const { state: previewState, isPreviewing, setPreviewTarget } = useViewAs();
    const iframeWindow = ref(null);
    const details = ref(null);

    window.addEventListener('message', async (event) => {
      const eventType = event.data.event_type
      console.log('event admin',eventType)
      switch (eventType) {
        case 'close_preview':
          setPreviewTarget({});
          isPreviewing.value = false;
          break;
        // case 'get_details':
        //   iframeWindow.value.contentWindow.postMessage({event_type:'preview',...details.value},process.env.VUE_APP_SAAS_URL);
        //   break;
      
        default:
          break;
      }
        console.log('Message received in iframe:', event.data, 'Origin:', event.origin);
    });

    async function handleIframeLoaded() {
      console.log('onload')
      const { data: previewLoginCreds } = await axios.get(`auth/preview-access/${previewState.previewTarget.id}`);
      details.value = previewLoginCreds;
      console.log('details',details.value)
      setTimeout(()=>{
        let msg = iframeWindow.value.contentWindow.postMessage({event_type:'preview',...details.value},process.env.VUE_APP_SAAS_URL);
        console.log('message res',msg)
      },200)
    }

    async function closePreview(){
      // setPreviewTarget({});
      // isPreviewing.value = false;
      iframeWindow.value.contentWindow.postMessage({event_type:'close'},process.env.VUE_APP_SAAS_URL);
    }

    return {
      closePreview,
      iframeUrl: `${process.env.VUE_APP_SAAS_URL}/?accessMode=preview`,
      previewState,
      setPreviewTarget,
      isPreviewing,

      iframeWindow,
      handleIframeLoaded,
    };
  },
};
</script>

<style>
#previewWindow {
  width: 100%;
  min-height: 750px;
}
</style>
