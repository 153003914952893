<template>
  <div class="d-flex flex-column dashboard px-4 py-4 w-100">
    <h2 class="mb-4 cursive-font">Account Archive</h2>

    <table-with-filters :options="tableOptions" :customColumns="customColumns">

      <!-- User Profile -->
      <template v-slot:user-name="{ item }">
        <div>
          <v-avatar size="30px">
            <img :src="resolveProfilePic(item)" alt="" loading="lazy"/>
          </v-avatar>
          <span class="ml-2 text-capitalize ">{{ item.first_name }} {{ item.last_name }}</span>
        </div>
      </template>

       <!-- User Profile -->
       <template v-slot:account_type="{ item }">
        <div>
          <span class="ml-2">{{ item.account_type == 'va'? "Virtual Client" : "Client" }}</span>
        </div>
      </template>

      <!-- preview Button -->
      <template v-slot:preview-btn="{ item }">
        <div class="box">
          <v-btn color="primary" small text @click="removeAccount(item.id)"> Restore </v-btn>
        </div>
      </template>

    </table-with-filters>

    <view-as v-if="isPreviewing"></view-as>

    <v-alert :type="isError ? 'error' : 'success'" class="popup-message" v-if="isVisible">
      {{ message }}
    </v-alert>

  </div>
</template>

<script>
import { resolveProfilePic } from "@/helpers";
import { useViewAs } from "@/composables/accounts/view-as.js";
import { ref } from '@vue/composition-api'
import ViewAs from "@/components/accounts/ViewAs.vue";
import TableWithFilters from "@/components/common/TableWithFilters.vue";
import axios from '@axios';

export default {
  name: "Accounts",
  components: { TableWithFilters, ViewAs },
  setup() {
    const isVisible = ref(false)
    const isError = ref(false)
    const message = ref(null)

    const switch1 = ref(true)
    const tableHeaders = [
      { text: "User", value: "username", sortable: true },
      { text: "Email", value: "email", sortable: false },
      { text: "Type", value: "account_type", sortable: false },
      { text: "", value: "previewBtn", sortable: false }
    ];

    const customColumns = [
      { slotName: "user-name", itemValue: "username" },
      { slotName: "preview-btn", itemValue: "previewBtn" }
    ];

    const filterFields = [
      { label: "Client Name", value: "username", component: "v-text-field" },
      { label: "Client Email", value: "email", component: "v-text-field" },
      { label: "User Type", value: "account_type", component: "v-select", items: ['client', 'va'] },
    ];

    const { setPreviewTarget, isPreviewing } = useViewAs();

    const setAlertData = (msg = 'Hello', err, show, duration) => {
      message.value = msg
      isError.value = err
      isVisible.value = show

      setTimeout(() => {
        message.value = null
        isError.value = false
        isVisible.value = false
      }, duration)
    }

    const removeAccount = async (id) => {
      try {
        const data = await axios.post(`/user/${id}/enable`,{})
        if (data) {
          setAlertData('Account Enable Successfully', false, true, 2500)
        }
      } catch (err) {
        setAlertData('Account Not Enable', true, true, 2500)
        console.log('Error : ', err)
        throw err
      }

    }


    return {
      tableHeaders,
      removeAccount,
      tableOptions: {
        endpoint: "/user/disabled-user",
        headers: tableHeaders,
        filtersTitle: "Filter Users",
        filterFields,
      },
      customColumns,
      switch1,
      isPreviewing,
      setPreviewTarget,
      resolveProfilePic,
      isVisible,
      isError,
      message
    };
  },
};
</script>

<style scoped>
.box {
  display: flex;
  gap: 2rem;
  align-items: center;
  justify-content: center;
}

.popup-message {
  position: fixed;
  right: 10px;
  top: 75px;
  width: 300px !important;
  z-index: 999;
  padding: 0.8rem;
  border-radius: 10px;
  font-weight: 500;
}
</style>